<template>
  <div :class="['payment-card', { 'payment-card_active': props.isActive }]">
    <div :class="cardClasses(methodName)">
      <span v-if="tag" class="payment-card__tag">
        {{ props.tag }}
      </span>
      <atomic-image
        :class="['payment-card__label', { 'payment-card__label_small': isScaledDown }]"
        :src="path"
        not-lazy
      />
    </div>
    <div class="payment-card__method-name" :title="title">
      {{ title }}
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps<{
    title: string;
    path: string;
    isScaledDown: boolean;
    isActive: boolean;
    methodName?: string | number;
    tag?: string | null;
  }>();

  const cardClasses = (methodName?: string | number) => {
    if (!methodName) return 'payment-card__img';
    return `payment-card__img payment-card__img_${formatPayMethodKey(methodName.toString(), '-')}`;
  };
</script>

<style src="~/assets/styles/components/payment/card.scss" lang="scss" />
