<template>
  <div class="form-input-payment">
    <div class="form-input-payment__title">{{ props.paymentGroup.groupName }}</div>
    <div class="form-input-payment__list">
      <payment-card
        v-for="item in viewList"
        :key="item.id"
        :method-name="item.methodName"
        :path="item.path"
        :title="item.name"
        :isScaledDown="item.isScaledDown"
        :is-active="isSelectedPayment(item)"
        :tag="item.tag"
        @click="selectItem(item)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import type { IPaymentGroup, IPaymentMethod, IPaymentProvider } from '@skeleton/core/types';
  import { scaledPayMethodIcon, showProviders, PayMethodToTitle } from '@skeleton/consts/method';

  enum ViewItemType {
    Method = 'method',
    Provider = 'provider'
  }

  interface IViewItem {
    id: string;
    methodId: string;
    endpoint?: string | number;
    name: string;
    path: string;
    isScaledDown: boolean;
    type: ViewItemType;
    methodName: string;
    tag: string | null;
  }

  interface IProps {
    activeProvider?: IPaymentProvider;
    activePaymentMethod?: IPaymentMethod;
    paymentGroup: IPaymentGroup;
    balanceOperation: 'deposit' | 'withdraw'
  }

  const emit = defineEmits(['update:activeProvider', 'update:activePaymentMethod']);

  const props = defineProps<IProps>();

  const { public: { gamehubCdn }, } = useRuntimeConfig();

  const viewList = computed(():IViewItem[] => {
    let list: IViewItem[] = [];

    props.paymentGroup.paymentMethods.forEach((item) => {

      const isScaledDown = !scaledPayMethodIcon.includes(item.method);

      if (showProviders.includes(item.method)) {
        const endpoints = item.fields
          ?.find((provider) => provider.key === 'endpointId');

        const providers: IPaymentProvider[] = props.balanceOperation === 'deposit'
          ? endpoints?.labels?.depositEndpoints || []
          : endpoints?.labels?.withdrawalEndpoints || [];

        const transformedProviders = providers.map((provider) => ({
          id: provider.id,
          methodId: item.methodId,
          name: provider.name,
          path: `${gamehubCdn}/payments/${provider.id}.svg`,
          type: ViewItemType.Provider,
          endpoint: provider.endpoint,
          methodName: item.method,
          tag: item.tag,
          isScaledDown
        }));

        list = [...list, ...transformedProviders];
      } else {
        list.push({
          id: item.methodId,
          methodId: item.methodId,
          name: getTitle(item.method),
          path: getPaymentCardImg(item.method),
          type: ViewItemType.Method,
          methodName: item.method,
          tag: item.tag,
          isScaledDown
        });
      }
    });

    return list;
  });

  const isSelectedPayment = (item: IViewItem) => {
    if (props.activePaymentMethod?.methodId && item.type === ViewItemType.Method) {
      return item.id === props.activePaymentMethod.methodId;
    }

    if (props.activeProvider && item.type === ViewItemType.Provider) {
      return item.id === props.activeProvider?.id;
    }
    return false;
  };

  const selectItem = (item) => {
    const foundMethod = props.paymentGroup.paymentMethods.find((method) => method.methodId === item.methodId);

    if (!foundMethod) return;

    emit('update:activePaymentMethod', foundMethod);

    if (item.type === ViewItemType.Provider) {
      emit('update:activeProvider', {
        id: item.id,
        endpoint: item.endpoint,
        name: item.name
      });
    } else {
      emit('update:activeProvider', undefined);
    }
  };

  const getTitle = (method: string): string => {
    return PayMethodToTitle[method] || formatPayMethodKey(method);
  };

  const getPaymentCardImg = (method: string) => {
    return `${gamehubCdn}/payments/${formatPayMethodKey(method)}.svg`;
  };
</script>

<style lang="scss" src="~/assets/styles/components/form/input/payment.scss" />